import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/scss/bootstrap.scss'
import 'animate.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import './assets/scss/style.scss'
import 'bootstrap'
import CoolLightBox from 'vue-cool-lightbox'
import VueScrollActive from 'vue-scrollactive'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
//main.js
// import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
// import 'vue-directive-image-previewer/dist/assets/style.css'
// Vue.use(VueDirectiveImagePreviewer)
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// iconfont
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
    ak: 'Fj2aQsuvtiTcqGwCnbnVtCx67PrKdZLj'
})
Vue.use(ElementUI);
Vue.prototype.$video = Video  // 在vue的原生里添加了Video这个标签，增强了vue的功能性
Vue.config.productionTip = false
Vue.use(CoolLightBox)
Vue.use(VueScrollActive)

new Vue({
    router,
    created() {
        AOS.init({
            duration: 800,
            once: true,
        })
    },
    render: h => h(App)
}).$mount('#app')
