<template>
  <ul :class="[
            `brand-list`,
            {'brand-style-1': brandStyle === 1},
            {'brand-style-2': brandStyle === 2},
            {'brand-style-2': brandStyle === 3},
            {'brand-style-2 variation-2': brandStyle === 4},
        ]">
    <li v-for="(brand, index) in brandList" :key="index" class="item-li">
      <a :href="brand.href" class="item-a"><img :src="brand.image" alt="Brand Image" /></a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Brand',
  props: {
    brandList: {},
    brandStyle: {
      type: Number,
      default: 1
    }
  }
}
</script>
<style lang="scss" scoped>
.item-li {
  height: 75px;
  .item-a {
    width: 100%;
    height: 100%;
    position: relative;
    img {
      width: 80%;
      max-height: 75px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
}
</style>
