<template>
	<Layout :footer-style="2">
		<div class="banner_part">
			<div class="banner_bg_box">
				<div class="part_con">
					<div class="banner_bg">
						<img class="bg_1 banner_bg_con" :src="bg1" alt />
						<img class="bg_2 banner_bg_con" :src="bg2" alt />
						<img class="bg_t banner_bg_con" :src="bgt" alt />
						<img class="bg_t_2 banner_bg_con" :src="bgt" alt />
						<img class="bg_b banner_bg_con" :src="bgb" alt />
						<img class="bg_b_2 banner_bg_con" :src="bgb" alt />
					</div>
				</div>
			</div>
		</div>

		<!-- 飘带内容区 -->
		<div class="demo-slider-area slider-area bg-transparent slider-style-1 pb--100 pt--70">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="inner text-center">
							<h1 class="title display-two" data-aos="fade-up" data-aos-delay="100">
								云南高兴技术
								<br />
								<span class="theme-gradient">天地虽高人为兴</span> &
								<span class="theme-gradient">相互成就久专精</span>
								<br />Just do it !
							</h1>
							<p class="description" data-aos="fade-up" data-aos-delay="150">守初心、谋共赢、重共情、求共鸣</p>
							<div class="button-group" data-aos="fade-up" data-aos-delay="200">
								<a class="btn-default btn-large round btn-icon" href="#demo">
									向下探索
									<Icon name="arrow-down" />
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- End -->

		<Separator />

		<!-- Start 解决方案 -->
		<div class="rn-demo-area rn-section-gap" id="demo">
			<div class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
				<div class="row">
					<div class="col-lg-12">
						<SectionTitle text-align="center" subtitle="All Experienced Solution" title="解决方案" description="下面是我们公司的解决方案 <br /> 快来看看有没有你在找寻的" data-aos="fade-up" />
					</div>
				</div>
				<div class="row row--15">
					<div class="col-lg-4 col-md-6 col-sm-12 col-12" v-for="(service, index) in solutionPages" :key="index" @click="goDetails(service)" v-if="index < 3">
						<div :class="`service gallery-style text-start`" data-aos="fade-up" data-aos-delay="70">
							<div class="inner">
								<div class="content">
									<h4 class="title solution-title" style="text-align: center;">{{ service.title }}</h4>
									<p class="description solution-description" v-html="service.description" style="text-align: center;" />
								</div>
								<div class="image">
									<img :src="service.image" style="width: 100%;height: 288px;background-size:100% 100%;" alt="Service Images" />
								</div>
							</div>
						</div>
					</div>
					<div class="button-group text-center mt--20" data-aos="fade-up" data-aos-delay="200">
						<a class="btn-default btn-large round btn-icon" href="/solution">
							查看更多
							<Icon name="arrow-right" />
						</a>
					</div>
				</div>
			</div>
		</div>
		<!-- End 解决方案 -->

		<Separator />
		<!-- Start 用户故事 -->
		<div class="rn-demo-area rn-section-gap" id="user-story">
			<div class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
				<div class="row">
					<div class="col-lg-12">
						<SectionTitle text-align="center" subtitle="All User Story" title="用户故事" description="下面是我们公司的用户故事 <br /> 一起来感受专业态度带来的不同" data-aos="fade-up" />
					</div>
				</div>
				<div class="row row--15">
					<div class="col-lg-4 col-md-6 col-12" v-for="(testimonial, index) in userStoryList" :key="index" v-if="index < 3">
						<router-link target="_blank" :to="`/user-story#user-story-${testimonial.id}`">
							<Testimonial :testimonial="testimonial" :testimonial-style="3" />
						</router-link>
					</div>
				</div>
				<div class="button-group text-center mt--20" data-aos="fade-up" data-aos-delay="200">
					<a class="btn-default btn-large round btn-icon" href="/user-story">
						查看更多
						<Icon name="arrow-right" />
					</a>
				</div>
			</div>
		</div>
		<!-- End 用户故事 -->
		<Separator />
		<!-- Start 成功案列 -->
		<div class="rn-demo-area rn-section-gap" id="success-case">
			<div class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
				<div class="row">
					<div class="col-lg-12">
						<SectionTitle text-align="center" subtitle="All Successful Case" title="成功案例" description="下面是我们公司的成功案列 <br /> 与你分享相互成就的喜悦和成长" data-aos="fade-up" />
					</div>
				</div>
				<div class="row row--15">
					<div class="col-lg-4 col-md-6 col-12 mt--40" v-for="(page, index) in casePages" :key="index " data-aos="fade-up" :data-aos-delay="100 + index" v-if="index < 3">
						<div class="single-demo">
							<router-link target="_blank" :to="`/case-details/${page.id}`">
								<div class="thumbnail">
									<img class="image-dark" style="width: 100%;height: 470px;background-size: 100% 100%;" :src="page.topBackgroundImg" alt="Corporate images" />
									<img class="image-light" style="width: 100%;height: 470px;background-size: 100% 100%;" :src="page.topBackgroundImg" alt="Corporate images" />
									<span v-if="page.badge" class="label-badge">{{ page.description }}</span>
								</div>
							</router-link>
							<h4 class="title">
								<router-link target="_blank" :to="`/case-details/${page.id}`">{{ page.title }}</router-link>
							</h4>
						</div>
					</div>
					<div class="button-group text-center mt--20" data-aos="fade-up" data-aos-delay="200">
						<a class="btn-default btn-large round btn-icon" href="/success-case">
							查看更多
							<Icon name="arrow-right" />
						</a>
					</div>
				</div>
			</div>
		</div>
		<!-- End 成功案列 -->
		<Separator />
		<!-- Start Our 技术分享 -->
		<div class="rn-demo-area rn-section-gap" id="blog">
			<div class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
				<div class="row">
					<div class="col-lg-12">
						<SectionTitle text-align="center" subtitle="All Technical Blog" title="技术分享" description="下面是我们公司的技术分享 <br /> 欢迎沟通交流互相学习共同进步" data-aos="fade-up" />
					</div>
				</div>
				<div class="row row--15">
					<div class="row row--15">
						<div class="col-lg-6 mt--30" data-aos="slide-up" data-aos-duration="800" :data-aos-delay="100 + index" v-for="(blog, index) in posts" :key="index" v-if="index < 4">
							<BlogPost :blog="blog" blog-post-style="list" />
						</div>
					</div>
					<div class="button-group text-center mt--20" data-aos="fade-up" data-aos-delay="200">
						<a class="btn-default btn-large round btn-icon" href="/blog">
							查看更多
							<Icon name="arrow-right" />
						</a>
					</div>
				</div>
			</div>
		</div>
		<!-- End Our 技术分享 -->

		<!-- Start Our 关于我们 -->
		<div class="rn-demo-area rn-section-gap" id="about">
			<div class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
				<div class="row">
					<div class="col-lg-12">
						<SectionTitle text-align="center" subtitle="About US" title="关于我们" description="些许介绍，丰富的内涵难以简介" data-aos="fade-up" />
					</div>
				</div>
				<AboutOne :image="require(`@/assets/images/about/img.png`)" />
			</div>
		</div>
		<!-- End Our 关于我们 -->
	</Layout>
</template>

<script>
	import Layout from '@/components/common/Layout'
	import Icon from '@/components/icon/Icon'
	import Separator from '@/components/elements/separator/Separator'
	import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
	import AboutOne from '@/components/elements/about/AboutOne'
	import BlogPost from "@/components/blog/BlogPost";
	import Testimonial from "@/components/elements/testimonial/Testimonial";
	import { caseList, solutionList, userStoryList } from "@/database/database.js"
	import BlogPostMixin from '@/mixins/BlogPostMixin'

	export default {
		name: 'Index',
		components: { Icon, Layout, Separator, SectionTitle, AboutOne, BlogPost, Testimonial },
		mixins: [BlogPostMixin],
		data() {
			return {
				bg1: require('@/assets/images/demo/bg-1.png'),
				bg2: require('@/assets/images/demo/bg-2.png'),
				bgt: require('@/assets/images/demo/bg-t.png'),
				bgb: require('@/assets/images/demo/bg-b.png'),
				//解决方案
				solutionPages: solutionList,
				//故事
				userStoryList: userStoryList,
				//案列
				casePages: caseList,
				//技术分享


			}
		},
		created() {
			console.log('%c ' +
				'                    _   _   _   _____   _   _   _   __   _   _____   _____   _   _____    _____  \n' +
				'                   | | | | | | /  ___| | | | | | | |  \\ | | /  ___/ |  _  \\ | | |  _  \\  | ____| \n' +
				'                   | |_| | | | | |     | |_| | | | |   \\| | | |___  | |_| | | | | |_| |  | |__   \n' +
				'                   |  _  | | | | |  _  |  _  | | | | |\\   | \\___  \\ |  ___/ | | |  _  /  |  __|  \n' +
				'                   | | | | | | | |_| | | | | | | | | | \\  |  ___| | | |     | | | | \\ \\  | |___  \n' +
				'                   |_| |_| |_| \\_____/ |_| |_| |_| |_|  \\_| /_____/ |_|     |_| |_|  \\_\\ |_____| ',
				'color: red;');
			console.log("%c                                                                                             \n" +
				"                                                     z                                                  \n" +
				"                                                   zzzzz                                                  \n" +
				"                                         zzzzzz      z                                                    \n" +
				"                                    vzzzzzz                  zaaauu111i1uuaazzv                        \n" +
				"                                 zzzzzzu                   zzzaa!!ii11uaazzzvvv                        \n" +
				"                               zzzzzuu                    zz33!ii11uaazzvvvvv                          \n" +
				"                             zzzzzuu                                                                   \n" +
				"                           zzzzzauu                                                                    \n" +
				"                          zzzzzuuu                   ;zzaaauuu1111uuuaaazzzzzv                         \n" +
				"                         zzzzzaau                   zzzzaa!iiii111uuuaaazzzzz                          \n" +
				"                        zzzzzaaa                   vz333!!!iiii111uuuaaazzz                            \n" +
				"                       aaaaaaaaa                                                                       \n" +
				"                      zaaaaaaaaa                 -aaauuuu1111iiiii!!!!!333333                          \n" +
				"                      aaaaaaaaaa               zaaaaauuuu1111iiiii!!!!!33333                           \n" +
				"                      uuuuzzzzzz              zzzaaaauuuu1111iiiii!!!!33333                            \n" +
				"                      uuuuzzzzzz             zzzaaaaauuuu1111iiiii!!!!33u    vv                        \n" +
				"                      uuuzzzzzzzz           zzz1333*                        zzv                        \n" +
				"                      u11zzzzzzzz+         z!333333                        zzz                         \n" +
				"                      111zzzzzzzzz.       !!!!!!!!                        izzz                         \n" +
				"                      u111vvvvvvvvvv     !!!!!!!!!                       iaaaa                         \n" +
				"                       111vvvvvvvvvvv   iiiiiiiii                      11aaaa                          \n" +
				"                        iivvvvvvvvvv   iiiiiiiiii                    111uuuu                           \n" +
				"                         iivvvvvvvv   1111111111                  .uuuuuuuu+                           \n" +
				"                          iivvvvvv  ^uuuuuuuuuuu               uuuuuuu1111                             \n" +
				"                           !ivvvv  ouuuuuuuuuuu  zaaaaaaaaaaaaaaaaaa11111                              \n" +
				"                             !vv  zaaaaaaaaaaaa  aaaaaaaaaaaaaaaaa11111                                \n" +
				"                                 aaaaaaaaaaaaa  ~zzzzzzzzzzzzzzziiiii.                                 \n" +
				"                                 zzzzzzzzzzzzz  zzzzzzzzzzzzziiiiii                                    \n" +
				"                                    1zzzzzzzz  +zzzzzzzzzz!!!!ii                                       \n" +
				"                                        .vvvv  vvvvvv!!!!!!^                                           \n" +
				"                                                                                                       \n" +
				"                                              云南高兴技术                                               \n",
				'color: blue; background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;'
			)
		},
		methods: {
			goDetails(row) {
				// this.$router.push({
				// 	path: `/solution-details/${row.id}`,
				// })
				let routeUrl = this.$router.resolve({
					path: `/solution-details/${row.id}`,
				});
				window.open(routeUrl.href, '_blank');

			},
		},
	}
</script>
<style lang="scss" scoped>
	img {
		vertical-align: top;
	}

	@-webkit-keyframes topAni {
		0% {
			top: 150px;
			opacity: 0;
		}

		35% {
			opacity: 1;
		}

		75% {
			top: -150px;
			opacity: 0;
		}

		to {
			top: -150px;
			opacity: 0;
		}
	}

	@keyframes topAni {
		0% {
			top: 150px;
			opacity: 0;
		}

		35% {
			opacity: 1;
		}

		75% {
			top: -150px;
			opacity: 0;
		}

		to {
			top: -150px;
			opacity: 0;
		}
	}

	@-webkit-keyframes leftAni {
		0% {
			left: -150px;
		}

		75% {
			left: 150px;
		}

		to {
			left: 150px;
		}
	}

	@keyframes leftAni {
		0% {
			left: -150px;
		}

		75% {
			left: 150px;
		}

		to {
			left: 150px;
		}
	}

	.banner_part {
		// position: relative;
	}

	.banner_bg_box {
		z-index: -1;
		position: absolute;
		top: 0;
		width: 100%;
		height: 2000px;
		overflow: hidden;

		.part_con {
			position: relative;
			width: 80%;
			max-width: 1543px;
			margin: 0 0 0 20%;
			z-index: 10;
			height: 100vh;

			.banner_bg {
				position: absolute;
				top: 0;
				left: 50%;
				width: 100%;

				.banner_bg_con {
					position: absolute;
					top: 0;
					left: 0;
				}

				.bg_1 {
					width: 50%;
					margin-top: -50px;
					margin-left: -50px;
				}

				.bg_2 {
					width: 60%;
					margin-top: 0;
					margin-left: -100px;
				}

				.bg_t {
					width: 90%;
					margin-top: -380px;
					margin-left: -360px;
					opacity: 1;
					-webkit-animation: topAni 5s cubic-bezier(0.27, 0.61, 0.17, 0.73)
							infinite,
						leftAni 5s linear infinite;
					animation: topAni 5s cubic-bezier(0.27, 0.61, 0.17, 0.73) infinite,
						leftAni 5s linear infinite;
				}

				.bg_t_2 {
					width: 90%;
					margin-top: -380px;
					margin-left: -360px;
					opacity: 0;
					-webkit-animation: topAni 5s cubic-bezier(0.27, 0.61, 0.17, 0.73) 2.5s
							infinite,
						leftAni 5s linear 2.5s infinite;
					animation: topAni 5s cubic-bezier(0.27, 0.61, 0.17, 0.73) 2.5s infinite,
						leftAni 5s linear 2.5s infinite;
				}

				.bg_b {
					width: 90%;
					opacity: 1;
					margin-top: -100px;
					margin-left: -270px;
					-webkit-animation: topAni 6s cubic-bezier(0.27, 0.61, 0.17, 0.73) 1s
							infinite,
						leftAni 6s linear 1s infinite;
					animation: topAni 6s cubic-bezier(0.27, 0.61, 0.17, 0.73) 1s infinite,
						leftAni 6s linear 1s infinite;
				}

				.bg_b_2 {
					width: 90%;
					opacity: 0;
					margin-top: -100px;
					margin-left: -270px;
					-webkit-animation: topAni 6s cubic-bezier(0.27, 0.61, 0.17, 0.73) 4s
							infinite,
						leftAni 6s linear 4s infinite;
					animation: topAni 6s cubic-bezier(0.27, 0.61, 0.17, 0.73) 4s infinite,
						leftAni 6s linear 4s infinite;
				}
			}
		}
	}

	.solution-description {
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3; /*超出两行隐藏*/
	}

	.solution-title {
		overflow: hidden; //超出的文本隐藏
		text-overflow: ellipsis; //溢出用省略号显示
		white-space: nowrap; //溢出不换行
	}
</style>


