import { isNull } from './validate'
/*
 * js工具类集合
 *
 * @create 2019-12-16
 * @update 2020-8-10
 * @author coderWangJun
 */

/*------------------------------------字符串操作----------------------------------------------------------------*/

/**
 * 判断两个字符串是否相等
 * 注：强制比较，包括同一个字符，但是不同类型
 * @param {*} value1 参数1
 * @param {*} value2 参数2
 * @returns 返回true和false
 */
export const equals = (value1, value2) => {
    return Object.is(value1, value2)
}

/**
 * 去除字符串两边空格
 * @param {String} value 参数
 * @returns 返回处理后的字符串
 */
export const trim = value => {
    if (isNull(value)) return
    return value.replace(/(^\s*)|(\s*$)/g, '')
}

/**
 * 去除字符串左边空格
 * @param {String} value 参数
 * @returns 返回处理后的字符串
 */
export const trimLeft = value => {
    if (isNull(value)) return
    return value.replace(/(^\s*)/g, '')
}

/**
 * 去除字符串右边空格
 * @param {String} value 参数
 * @returns 返回处理后的字符串
 */
export const trimRight = value => {
    if (isNull(value)) return
    return value.replace(/(\s*$)/g, '')
}

/**
 * 去除字符串全部空格
 * @param {String} value 参数
 * @returns 返回处理后的字符串
 */
export const trimAll = value => {
    if (isNull(value)) return
    return value.replace(/\s+/g, '')
}

/**
 * 生成UUID
 * @param len 生成的位数，默认32位
 * @param radix 进制
 * @returns 返回UUID字符串，例如：5e71b6a38364c189ab1229bf5c2d5695
 */
export const getUUID = (len = 32, radix = 16) => {
    const CHARS = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
    const uuid = []
    let i
    radix = radix || CHARS.length
    if (len) {
        for (i = 0; i < len; i++) uuid[i] = CHARS[0 | (Math.random() * radix)]
    } else {
        let r
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
        uuid[14] = '4'
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | (Math.random() * 16)
                uuid[i] = CHARS[i == 19 ? (r & 0x3) | 0x8 : r]
            }
        }
    }
    return uuid.join('')
}

/**
 * 生成GUID
 * @returns 返回GUID字符串，例如：e854e2ec-063c-1ee7-942f-57c5733ce0cb
 */
export const getGUID = () => {
    const s4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    }
    return (
        s4() +
        s4() +
        '-' +
        s4() +
        '-' +
        s4() +
        '-' +
        s4() +
        '-' +
        s4() +
        s4() +
        s4()
    )
}

/**
 * 字符串强度，数字越大代表强度越强，目前强度为：0-较弱、1-弱、2-中、3-很强
 * @returns 返回字符串强度
 */
export const stringStrength = (strVal = '') => {
    let strength = 0;
    if (strVal.length >= 6) {
        if (/[a-zA-Z]+/.test(strVal) && /[0-9]+/.test(strVal) && /\W+\D+/.test(strVal)) {
            strength = 3;
        } else if (/[a-zA-Z]+/.test(strVal) || /[0-9]+/.test(strVal) || /\W+\D+/.test(strVal)) {
            if (/[a-zA-Z]+/.test(strVal) && /[0-9]+/.test(strVal)) {
                strength = 2;
            } else if (/\[a-zA-Z]+/.test(strVal) && /\W+\D+/.test(strVal)) {
                strength = 2;
            } else if (/[0-9]+/.test(strVal) && /\W+\D+/.test(strVal)) {
                strength = 2;
            } else {
                strength = 1;
            }
        }
    } else {
        strength = 0;
    }
    return strength;
}

/*------------------------------------数字操作----------------------------------------------------------------*/

/**
 * 生成指定大小的随机数
 * @param {Number} n 生成随机数的开始数字，默认0
 * @param {Number} m 生成随机数的结束数字，默认9
 * @returns 返回指定位数的随机数，默认是0-9的一位数
 */
export const getRandom = (n = 0, m = 9) => {
    return Math.floor(Math.random() * m + n)
}

/**
 * 生成指定位数的随机数
 * @param {Number} n 生成数字的位数，默认是1，代表0-9之间的一位数；如果是2，代表10-99之间的两位数；其他位数以此类推
 * @returns 返回指定位数的随机数
 */
export const getRandomDigit = (n = 1) => {
    return Math.floor(
        (Math.random() + Math.floor(Math.random() * 9 + 1)) * Math.pow(10, n - 1)
    )
}

/*------------------------------------常用类型转换----------------------------------------------------------------*/

/**
 * map转object
 * @param {Map} map 参数
 * @returns 返回Object
 */
export const mapToObject = map => {
    const obj = Object.create(null)
    for (const [k, v] of map) {
        obj[k] = v
    }
    return obj
}

/*
 * object转map
 * @param {Object} obj 参数
 * @returns 返回Map
 */
export const objectToMap = obj => {
    const map = new Map()
    for (const k of Object.keys(obj)) {
        map.set(k, obj[k])
    }
    return map
}

/**
 * map转json
 * @param {Map} map 参数
 * @returns 返回Json
 */
export const mapToJson = map => {
    return JSON.stringify(this.mapToObject(map))
}

/**
 * json转map
 * @param {Json} json 参数
 * @returns 返回Map
 */
export const jsonToMap = json => {
    return this.objectToMap(JSON.parse(json))
}

/*------------------------------------日期相关----------------------------------------------------------------*/

/**
 * 获得当前的日期Date是周几
 * @param {Date} date 可以为空，默认当前日期；也可以指定任意日期Date
 * @param {String} type 可为空，默认为zh；zh：返回周一，周二的中文；参数为空：返回1,2的数字
 * @returns 返回对应的中文的周几或者数字的周几
 */
export const getWeekDay = (date = new Date(), type = 'zh') => {
    if (isNull(date)) return
    let day = ''
    if (type && type == 'zh') {
        day = new Array('周日', '周一', '周二', '周三', '周四', '周五', '周六')[
            date.getDay()
        ]
    } else {
        day = new Array('7', '1', '2', '3', '4', '5', '6')[date.getDay()]
    }
    return day
}

/**
 * 获得加减年数后的日期Date
 * @param {Date} date 可以为空，默认当前日期；也可以指定任意日期Date
 * @param {Number} num 加减年数的数量，用-1和+1年表示
 * @returns 返回加减年数后的日期Date
 */
export const getDiffYear = (date = new Date(), num = +1) => {
    date.setFullYear(date.getFullYear() + num)
    return date
}

/**
 * 获得加减月数后的日期
 * @param {Date} date 可以为空，默认当前日期；也可以指定任意日期Date
 * @param {Number} num 加减月数的数量，用-1和+1月表示
 * @returns 返回加减月数后的日期Date
 */
export const getDiffMonth = (date = new Date(), num = +1) => {
    date.setMonth(date.getMonth() + num)
    return date
}

/**
 * 获得加减天数后的日期
 * @param {Date} date 可以为空，默认当前日期；也可以指定任意日期Date
 * @param {Number} num 加减天数的数量，用-1和+1天表示
 * @returns 返回加减天数后的日期Date
 */
export const getDiffDate = (date = new Date(), num = +1) => {
    date.setDate(date.getDate() + num)
    return date
}

/**
 * 计算两个时间之间相差的天数
 * @param {Date} date1 第一个时间
 * @param {Date} date2 第二个时间
 * @returns 返回两个时间Date相差的天数；参数为空返回0；返回负数代表第一个时间大于第二个时间；返回正数表示第一个时间小于第二个时间
 */
export const getDiffTimeNum = (date1, date2) => {
    if (isNull(date1) || isNull(date2)) return 0
    const time1 = typeof date1 == 'number' ? date1 : new Date(date1).getTime()
    const time2 = typeof date2 == 'number' ? date2 : new Date(date2).getTime()
    return (time2 - time1) / (24 * 60 * 60 * 1000)
}

/**
 * 日期Date格式化为日期字符串
 * @param {Date} date 可以为空，默认当前日期；也可以指定任意日期Date
 * @param {String} formatStr 需要转换的日期字符串格式，支持yyyy/MM/dd HH:mm:ss，dd/MM/yyyy HH:mm:ss等多种常用的格式；默认转为yyyy-MM-dd
 * @returns 返回指定格式的日期字符串
 */
export const formatDateToStr = (
    date = new Date(),
    formatStr = 'yyyy-MM-dd'
) => {
    // 日期字符串格式化
    const o = {
        'M+': date.getMonth() + 1, // 月
        'd+': date.getDate(), // 日
        'h+': date.getHours() % 12 == 0 ? 12 : date.getHours() % 12, // 12小时制
        'H+': date.getHours(), // 24小时制
        'm+': date.getMinutes(), // 分钟
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds() //毫秒
    }
    if (/(y+)/.test(formatStr)) {
        formatStr = formatStr.replace(
            RegExp.$1,
            (date.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
    }
    for (const k in o) {
        if (new RegExp('(' + k + ')').test(formatStr)) {
            formatStr = formatStr.replace(
                RegExp.$1,
                RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
            )
        }
    }
    return formatStr
}

/**
 * 日期Date格式化为日期字符串 刚刚、几小时前、几分钟前 的格式
 * @param {number} t
 * @returns {string}
 */
export const formatTime = t => {
    const time = typeof t == 'number' ? t : new Date(t).getTime()
    if (('' + time).length === 10) {
        time = parseInt(time) * 1000
    } else {
        time = +time
    }
    const d = new Date(time)
    const now = Date.now()

    const diff = (now - d) / 1000

    if (diff < 30) {
        return '刚刚'
    } else if (diff < 3600) {
        // less 1 hour
        return Math.floor(diff / 60) + '分钟前'
    } else if (diff < 3600 * 24) {
        return Math.floor(diff / 3600) + '小时前'
    } else if (diff < 3600 * 24 * 30) {
        return Math.floor(diff / (3600 * 24)) + '天前'
    } else if (diff < 3600 * 24 * 365) {
        return Math.floor(diff / (3600 * 24 * 30)) + '月前'
    } else {
        return Math.floor(diff / (3600 * 24 * 365)) + '年前'
    }
}

/*------------------------------------数组操作----------------------------------------------------------------*/

/**
 * 递归通过父节点ID生成树结构 [平级的数据构造成tree结构]
 * @param {Array} datas 平级的数据
 * @param {Boolean} isRoot  是否返回root
 * @param {String | Number} parentid  父节点id
 * @param {Boolean} disabled  是否开启禁用，用于禁用上级相关的所有子级节点
 * @param {String | Number} disabledId  禁用节点的id，用于精准禁用某一个节点
 */
export const getTrees = ({
    datas,
    isRoot = true,
    parentid = 0,
    disabled = false,
    disabledId = ''
}) => {
    // 判断是否存在根级 [parentid = 0] 的标识
    const isRootParent = datas.some(
        item => item.parentid.toString() === parentid.toString()
    )

    const root = []
    const obj = datas.reduce((prev, next) => {
        if (disabled) {
            next.disabled = parseInt(next.isenable) === 0
        } else {
            next.disabled = next.id === disabledId
        }
        next.ischecked = false
        prev[next.id] = next

        // 1.0 不存在根级为0的parentid，直接放入容器返回
        if (!isRootParent) {
            root.push(next)
        }

        return prev
    }, {})

    // 2.0 存在根级为0的parentid，正常合并同类项
    if (isRootParent) {
        datas.forEach(item => {
            if (
                item.parentid.toString() === parentid.toString() ||
                !item.parentname
            ) {
                item.parentname = '根节点'
                root.push(item)
                return
            }

            const parentItem = obj[item.parentid]
            if (!Array.isArray(parentItem.children)) {
                parentItem.children = []
            }
            parentItem.children.push(item)
        })
    }

    return isRoot
        ? [{ id: '0', name: '根节点', parentid: '0', children: root }]
        : root
}

/**
 * 格式化字典的数据转化为数组
 * @param {String} dictCode 字典的code
 * @returns 返回格式化后的数组
 */
export const formatDictList = dictCode => {
    const dictObj = GLOBAL_DICT[dictCode],
        dictList = [];
    if (dictObj && Object.keys(dictObj).length) {
        for (const k in dictObj) {
            if (Object.hasOwnProperty.call(dictObj, k)) {
                const item = dictObj[k];
                item.key = item.value;
                item.val = item.label;
                dictList.push(item);
            }
        }
    }
    return dictList;
}

/*------------------------------------文件操作----------------------------------------------------------------*/

/**
 * 格式化文件大小自动转为B，KB，MB，GB
 * @param {Byte} size 文件的大小，单位byte字节
 * @returns 返回格式化后的字符串
 */
export const formatFileSize = size => {
    if (isNull(size)) return '0B'
    if (size < 1024) {
        return size + 'B'
    } else if (size < 1024 * 1024) {
        return (size / 1024).toFixed(2) + 'KB'
    } else if (size < 1024 * 1024 * 1024) {
        return (size / (1024 * 1024)).toFixed(2) + 'MB'
    } else {
        return (size / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
    }
}

/**
 * 获得文件名称
 * @param {*} value 文件的全名称，例如：123.jpg
 * @returns 返回文件的名称，包含后缀类型名称
 */
export const getFileName = value => {
    if (isNull(value) || isNull(value)) return
    return value.substring(0, value.lastIndexOf('.'))
}

/**
 * 获得文件后缀
 * @param {*} value 文文件的全名称，例如：123.jpg
 * @returns 返回文件后缀类型名称
 */
export const getFileSuffixName = value => {
    if (isNull(value)) return
    return value.substring(value.lastIndexOf('.') + 1)
}

/*------------------------------------其他方法----------------------------------------------------------------*/

/**
 * 对象属性过滤
 * @param {*} resJson
 * @param {*} newJson
 * @returns
 */
export const jsonFill = (resJson, newJson) => {
    for (const key in newJson) {
        if (resJson[key] !== undefined) {
            resJson[key] = newJson[key]
        }
    }
    return resJson
}

/**
 * 判断是否为移动端
 * @returns
 */
export const isMobile = () => {
    const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
    return flag
}
/**
 * 根据正确的身份证号码提取出生日日期，性别，年龄
 * @param {string} idcard
 * @returns {Object}
 */
export function getCardInfo(idcard) {
    const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    if (reg.test(idcard)) {
        let cardNum = idcard;
        //获取出生日期
        let birthday = cardNum.substring(6, 10) + "-" + cardNum.substring(10, 12) + "-" + cardNum.substring(12, 14);
        // 获取性别
        let sex = ''
        if (parseInt(cardNum.substr(16, 1)) % 2 == 1) {
            sex = "男"
        } else {
            sex = "女"
        }
        //获取年龄
        var myDate = new Date();
        var month = myDate.getMonth() + 1;
        var day = myDate.getDate();
        var age = myDate.getFullYear() - cardNum.substring(6, 10) - 1;
        if (
            cardNum.substring(10, 12) < month ||
            (cardNum.substring(10, 12) == month && cardNum.substring(12, 14) <= day)
        ) {
            age++;
        }
        return { birthday, sex, age }
    } else {
        return '请输入正确的身份证号码'
    }

}

/**
 * dict转换成tableprops中的list
 */
 export function dictObjectToTableTree(dict) {
  const datas = JSON.parse(JSON.stringify(dict))
  const list = []
  for (const key in datas) {
      if (Object.hasOwnProperty.call(datas, key)) {
          const ele = {
              key: datas[key].value,
              val: datas[key].label
          }
          list.push(ele)
      }
  }
  return list
}