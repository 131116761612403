<template>
	<Layout>
		<!-- Start Contact Area  -->
		<div class="rwt-contact-area rn-section-gap">
			<div class="container">
				<div class="row">
					<div class="col-lg-12 mb--40">
						<SectionTitle text-align="center" subtitle="Contact Details" title="我们的联系地址" />
					</div>
				</div>
				<div class="row row--15">
					<div class="col-lg-12">
						<div class="rn-contact-address mt_dec--30">
							<div class="row">
								<div class="col-lg-4 col-md-6 col-12">
									<div class="rn-address">
										<div class="icon">
											<Icon name="headphones" :size="40" />
										</div>
										<div class="inner">
											<h4 class="title">我们的联系电话</h4>
											<p>
												<a href="tel:156 8770 0306">管先生：156 8770 0306</a>
											</p>
											<p>
												<a href="tel:153 6825 5564">杜先生：153 6825 5564</a>
											</p>
										</div>
									</div>
								</div>

								<div class="col-lg-4 col-md-6 col-12">
									<div class="rn-address">
										<div class="icon">
											<Icon name="mail" :size="40" />
										</div>
										<div class="inner">
											<h4 class="title">我们的邮箱地址</h4>
											<p>
												<a href="mailto:highinspire@outlook.com">highinspire@outlook.com</a>
											</p>
											<p>
												<a href="mailto:dujiayou0921@163.com">dujiayou0921@163.com</a>
											</p>
										</div>
									</div>
								</div>

								<div class="col-lg-4 col-md-6 col-12">
									<div class="rn-address">
										<div class="icon">
											<Icon name="map-pin" :size="40" />
										</div>
										<div class="inner">
											<h4 class="title">我们的位置</h4>
											<p>云南省昆明市五华区人民西路730号西府景苑2幢1705室</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row mt--40 row--15">
					<div class="col-lg-7">
						<ContactForm />
					</div>
					<div class="col-lg-5 mt_md--30 mt_sm--30">
						<BaiduMap />
					</div>
				</div>
			</div>
		</div>
		<!-- End Contact Area  -->
	</Layout>
</template>

<script>
	import Layout from '@/components/common/Layout'
	import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
	import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
	import Icon from "@/components/icon/Icon";
	import BaiduMap from "@/components/map/BaiduMap.vue";
	import ContactForm from "@/components/elements/contact/ContactForm";

	export default {
		name: 'ContactPage',
		components: { ContactForm, BaiduMap, Icon, SectionTitle, Breadcrumb, Layout }
	}
</script>
