<template>
	<Layout header-class="header-transparent" :show-newsletter="true" :show-cta="false">
		<!-- Start topper Area -->
		<div class="slider-area slider-style-1 height-850 background-box" data-black-overlay="2">
			<div class="videoContainer">
				<video class="fullscreenVideo" id="bgVid" playsinline autoplay muted loop>
					<source :src="videoData.src" type="video/mp4" />
				</video>
			</div>
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="inner pt--80 text-center">
							<div>
								<h1 class="rn-sub-badge" data-aos="fade-up" data-aos-delay="100">
									<span class="theme-gradient">天地虽高人为兴，相互成就久专精.</span>
								</h1>
							</div>
							<h3 class="title display-one" data-aos="fade-up" data-aos-delay="150">
								守初心、谋共赢、重共情、求共鸣
								<br />协齐心、并肩拼、快且勤
								<br />共鸣、共情、共赢
							</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- End topper Area -->

		<!-- Start counter Area -->
		<div class="rwt-counterup-area pb--30">
			<div class="container">
				<Counter :counter-style="5" text-align="center" :counter-data="counterData" column="col-lg-3 col-md-6 col-sm-6 col-12" />
			</div>
		</div>
		<!-- End counter Area -->

		<Separator />

		<!-- Start Service Area -->
		<div class="rn-service-area rn-section-gap" id="service">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<SectionTitle text-align="center" subtitle="我们能为您做什么" title="为您提供的服务" description="包括但不限于" data-aos="fade-up" />
					</div>
				</div>
				<ServiceOne service-style="service__style--1 bg-color-blackest radius mt--25" icon-size="62" text-align="center" />
			</div>
		</div>
		<!-- End Service Area -->

		<div class="rwt-timeline-area rn-section-gapBottom">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<Timeline :timeline-data="timelineData" :timeline-style="3" />
					</div>
				</div>
			</div>
		</div>

		<Separator />
		<!-- Start cooperation Area -->
		<div class="service-area rn-section-gapTop" id="partner">
			<div class="container">
				<div class="row">
					<div class="col-lg-6">
						<div class="content" data-aos="fade-up">
							<h3 class="title">合作伙伴</h3>
						</div>
					</div>
					<div class="col-lg-6">
						<p class="mb--10" data-aos="fade-up">部分展示</p>
					</div>
				</div>
			</div>
		</div>
		<!-- End cooperation Area -->

		<!-- Start company Area -->
		<div class="rwt-brand-area pb--60 pt--30">
			<div class="container">
				<div class="row">
					<div class="col-lg-12 mt--10">
						<Brand :brand-list="brandList" :brand-style="3" />
					</div>
				</div>
			</div>
		</div>
		<!-- End company Area -->

		<Separator />

		<AboutOne :image="require(`@/assets/images/about/img.png`)" />

		<Separator />

		<!-- Start team Area -->
		<div class="rwt-team-area rn-section-gap" id="team">
			<div class="container">
				<div class="row mb--20">
					<div class="col-lg-12">
						<SectionTitle text-align="center" subtitle="团队成员" title="风采格言展示" data-aos="fade-up" />
					</div>
				</div>
				<div class="row">
					<div class="col-lg-6 col-xl-3 col-md-6 col-12 mt--30" v-for="(teamMember, index) in teamData" :key="index">
						<Team :team-member="teamMember" :team-style="4" />
					</div>
				</div>
			</div>
		</div>
		<!-- End team Area -->
	</Layout>
</template>

<script>
	import Layout from '@/components/common/Layout'
	import Separator from '@/components/elements/separator/Separator'
	import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
	import AboutOne from '@/components/elements/about/AboutOne'
	import ServiceOne from '@/components/elements/service/ServiceOne'
	import Timeline from '@/components/elements/timeline/Timeline'
	import Counter from '@/components/elements/counterUp/Counter'
	import Brand from '@/components/elements/brand/Brand'
	import Team from '@/components/elements/team/Team'
	export default {
		name: 'About',
		components: { Team, Brand, Counter, Timeline, ServiceOne, AboutOne, SectionTitle, Separator, Layout },
		data() {
			return {
				videoData:
				{
					thumb: require(`@/assets/images/brand/ynnu.png`),
					src: require(`@/assets/video/wide-bg.mp4`),
				}
				,
				brandList: [
					{
						href: "https://aliyun.com/",
						image: require(`@/assets/images/brand/aliyun.png`)
					},
					{
						href: "https://www.nginx.com/",
						image: require(`@/assets/images/brand/nginx.png`)
					},
					{
						href: "https://www.189.cn/",
						image: require(`@/assets/images/brand/china-telecom.png`)
					},
					{
						href: "https://aliyun.com/",
						image: require(`@/assets/images/brand/norinco-group.png`)
					},
					{
						href: "https://www.ynnu.edu.cn/",
						image: require(`@/assets/images/brand/ynnu-matrix.png`)
					},
					{
						href: "http://www.repairct.com/",
						image: require(`@/assets/images/brand/repairct.png`)
					},
					{
						href: "https://www.ynskw.cn/",
						image: require(`@/assets/images/brand/skw.png`)
					},
					{
						href: "https://colab.research.google.com/",
						image: require(`@/assets/images/brand/colab.png`)
					},
					{
						href: "https://www.tapd.cn/",
						image: require(`@/assets/images/brand/tapd.png`)
					},
					{
						href: "https://www.toptal.com/",
						image: require(`@/assets/images/brand/toptal.png`)
					},
					{
						href: "https://www.thoughtworks.com/",
						image: require(`@/assets/images/brand/thoughtworks.png`)
					},
				],
				timelineData: [
					{
						id: '1',
						title: '分析调研',
						description: '具体场景分析 详细需求调研',
					},
					{
						id: '2',
						title: '设计划分',
						description: '整体功能设计 合理模块划分',
					},
					{
						id: '3',
						title: '预研实现',
						description: '底层架构设计 具体编码测试',
					},
					{
						id: '4',
						title: '反馈调整',
						description: '部署运维修复 持续改进升级',
					}
				],
				counterData: [
					{
						number: 199,
						title: '咨询了解',
					},
					{
						number: 175,
						title: '服务支撑',
					},
					{
						number: 19,
						title: '成功落地',
					},
					{
						number: 54,
						title: '探索钻研',
					}
				],
				teamData: [
					{
						image: 'team-1',
						name: '管先生',
						designation: 'initiator',
						location: '相互成就',
						description: '态度决定一切'
					},
					{
						image: 'team-2',
						name: '小杜',
						designation: 'senior web',
						location: '资深前端',
						description: '钻的更深，看的更透'
					},
					{
						image: 'team-3',
						name: '杨师',
						designation: 'architect',
						location: '大牛架构师',
						description: '好的架构是一门艺术'
					},
					{
						image: 'team-4',
						name: 'Lily',
						designation: 'UI',
						location: '灵动美工',
						description: '感知美，发现美，创造美'
					},
				]
			}
		}
	}
</script>

<style lang="scss" scoped>
	.background-box {
		position: relative;
	}
	.videoContainer {
		position: absolute;
		width: 100%;
		height: 100%;
		overflow: hidden;
		z-index: -100;
	}

	.videoContainer:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		display: block;
		z-index: -1;
		top: 0;
		left: 0;
		background: rgba(25, 29, 34, 0.65);
		background-size: cover;
	}

	.fullscreenVideo {
		width: 100%;
		height: 100%;
		object-fit: fill;
	}
</style>
