<template>
	<ul class="mainmenu">
		<li class="has-droupdown has-menu-child-item" :class="getclass('/')?'active':''">
			<router-link to="/">首页</router-link>
		</li>
		<li class="has-droupdown has-menu-child-item" :class="getclass('/solution')?'active':''">
			<a href="#">
				解决方案
				<Icon name="plus" class="collapse-icon plus" />
				<Icon name="minus" class="collapse-icon minus" />
			</a>

			<ul class="submenu">
				<li v-for="(item,index) in solutionlistData" :key="index">
					<router-link target="_blank" :to="`/solution-details/${item.id}`">{{item.title}}</router-link>
				</li>
			</ul>
		</li>

		<li class="has-droupdown has-menu-child-item" :class="getclass('/user-story')?'active':''">
			<a href="#">
				用户故事
				<Icon name="plus" class="collapse-icon plus" />
				<Icon name="minus" class="collapse-icon minus" />
			</a>
			<ul class="submenu">
				<li v-for="(item,index) in userStoryListData" :key="index">
					<router-link target="_blank" :to="`/user-story#user-story-${item.id}`">{{item.title}}</router-link>
				</li>
			</ul>
		</li>
		<li class="has-droupdown has-menu-child-item" :class="getclass('/success-case')?'active':''">
			<a href="#">
				成功案列
				<Icon name="plus" class="collapse-icon plus" />
				<Icon name="minus" class="collapse-icon minus" />
			</a>
			<ul class="submenu">
				<li v-for="(item,index) in caselistData" :key="index">
					<router-link target="_blank" :to="`/case-details/${item.id}`">{{item.title}}</router-link>
				</li>
			</ul>
		</li>
		<li :class="getclass('/blog')?'active':''">
			<router-link to="/blog">技术分享</router-link>
		</li>
		<li :class="getclass('/about')?'active':''">
			<router-link to="/about">关于我们</router-link>
		</li>
		<!-- <li><router-link to="/contact">联系我们</router-link></li> -->
	</ul>
</template>

<script>
	import Icon from "../../icon/Icon";
	import { caseList, solutionList, userStoryList } from "@/database/database.js"
	export default {
		name: 'Nav',
		components: { Icon },
		data() {
			return {
				path: '',
				caselistData: caseList,
				solutionlistData: solutionList,
				userStoryListData: userStoryList,
			}
		},
		created() {
			this.path = this.$route.path
		},
		mounted() {

		},
		methods: {
			getclass(e) {
				return e === this.path
			}
		}
	}
</script>
