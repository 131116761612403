<template>
	<Layout>
		<Breadcrumb title="技术分享" current-url="分享技术经验和思考沉淀" />

		<div class="rn-blog-area rn-section-gap">
			<div class="container">
				<div class="row row--30">
					<div class="col-lg-8">
						<div class="rbt-single-widget widget_search mt--40 mb--20">
							<div class="inner">
								<!-- <form class="blog-search" action onsubmit="return false">
									<input type="text" v-model="searchValue" clearable @clear="clearSearchValue" placeholder="Search ..." />
									<button class="search-button" @click.stop="search()">
										<Icon name="search" size="20" />
									</button>
								</form>-->
								<div class="blog-search-div" style="margin-top: 15px;">
									<el-input placeholder="请输入内容" clearable @clear="clearSearchValue" v-model="searchValue" @keyup.enter.native="search">
										<el-button slot="append" class="search-button" icon="el-icon-search" @click="search"></el-button>
									</el-input>
								</div>
							</div>
						</div>
						<Separator />
						<div class="row mt_dec--30">
							<div class="col-lg-12">
								<div class="row row--15">
									<div class="col-lg-12 mt--30" data-aos="slide-up" data-aos-duration="800" :data-aos-delay="100 + index" v-for="(blog, index) in searchList" :key="index">
										<BlogPost :blog="blog" blog-post-style="list" />
									</div>
								</div>
							</div>
							<div class="col-lg-12 text-center">
								<div class="rwt-load-more text-center mt--60">
									<a class="btn-default" href="#">
										查看更多
										<Icon name="loader" />
									</a>
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg-4 mt_md--40 mt_sm--40">
						<BlogSidebar />
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import Icon from '@/components/icon/Icon'
	import BlogPost from '@/components/blog/BlogPost'
	import Layout from '@/components/common/Layout'
	import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
	import BlogSidebar from '@/components/blog/BlogSidebar'
	import Separator from '@/components/elements/separator/Separator'
	import BlogPostMixin from '@/mixins/BlogPostMixin'

	export default {
		name: 'TechnologySharingList',
		components: { BlogSidebar, Icon, BlogPost, Layout, Breadcrumb, Separator },
		mixins: [BlogPostMixin],
		data() {
			return {

			}
		},
		created() {
		},
		mounted() {
		},
		methods: {

		},
	}
</script>
<style lang="scss" scoped>
	::v-deep .blog-search-div {
		position: relative;
		input {
			height: 60px;
			padding: 0 20px;
			color: var(--color-body);
			padding-right: 80px;
		}
		.el-input-group__append {
			// position: absolute;
			// right: 6px;
			// top: 50%;
			// transform: translateY(-50%);
			// border: 0 none;
			background: var(--color-primary-light);
			border: var(--color-primary-light);
			// width: 100%;
			// height: 100%;
			// height: 47px;
			// padding: 0 20px;
			// border-radius: 3px;
			// text-align: center;
			// font-size: 20px;
			i {
				color: var(--color-white);
				font-weight: 400;
			}
		}
	}
</style>