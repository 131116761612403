import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/index/Index.vue'
import About from '@/views/about'
import CaseDetails from '@/views/case/components/Details.vue'
import ContactPage from '@/views/contact/ContactPage'

import TechnologySharingList from '@/views/technology-sharing/List'
import TechnologySharingPage from '@/views/technology-sharing/DetailsPage'
import BlogCategoryPosts from '@/components/blog/BlogCategoryPosts'

import Solution from '@/views/solution/index.vue'
import SolutionDetails from '@/views/solution/components/Details.vue'

import UserStory from '@/views/user-story/index.vue'
import SuccessCase from '@/views/case/index.vue'



Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: Index,
        meta: {
            title: '云南高兴技术',
        },
    },
    {
        path: '/solution',
        name: 'Solution',
        component: Solution,
        meta: {
            title: '解决方案',
        },
    },
    {
        path: '/solution-details/:id',
        name: 'solution details',
        component: SolutionDetails,
        meta: {
            title: '方案详情',
        },
    },
    {
        path: '/case-details/:id',
        name: 'case details',
        component: CaseDetails,
        meta: {
            title: '案例详情',
        },
    },
    {
        path: '/user-story',
        name: 'UserStory',
        component: UserStory,
        meta: {
            title: '用户故事',
        },
    },
    {
        path: '/success-case',
        name: 'successCase',
        component: SuccessCase,
        meta: {
            title: '成功案列',
        },
    },
    {
        path: '/case-details/:id',
        name: 'CaseDetails',
        component: CaseDetails,
        meta: {
            title: '案例详情',
        },
    },

    {
        path: '/blog',
        name: 'TechnologySharingList',
        component: TechnologySharingList,
        meta: {
            title: '技术分享',
        },
    },
    {
        path: '/technology-sharing-details/:id',
        name: 'technology sharing details',
        component: TechnologySharingPage,
        meta: {
            title: '技术分享详情',
        },
    },
    {
        path: '/category/:slug',
        name: 'Blog Category List',
        component: BlogCategoryPosts,
        meta: {
            title: '技术分享标签分类',
        },
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            title: '关于我们',
        },
    },


    {
        path: '/contact',
        name: 'Contact',
        component: ContactPage,
        meta: {
            title: '联系我们',
        },
    },


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
