<template>
  <el-form ref="form" :rules="rules" class="contact-form-1" :model="formData"   @submit.native.prevent>
    <el-form-item prop="name" class="form-group">
      <input type="text" placeholder="你的名字" v-model="formData.name" />
    </el-form-item>
    <el-form-item prop="email" class="form-group">
      <input type="email" placeholder="电子邮件地址" v-model="formData.email" />
    </el-form-item>
    <el-form-item prop="phone" class="form-group">
      <input type="text" placeholder="电话号码" v-model="formData.phone" />
    </el-form-item>
    <el-form-item prop="subject" class="form-group">
      <input type="text" placeholder="一句话描述" v-model="formData.subject" />
    </el-form-item>
    <el-form-item class="form-group"  prop="detail">
      <textarea placeholder="详细信息" v-model="formData.detail"></textarea>
    </el-form-item>
    <el-form-item>
      <button class="btn-default btn-large" @click="sendEmail()">提交</button>
    </el-form-item>
    <div class="form-group" v-html="showResult ? result : null" />
  </el-form>
</template>

<script>
import { isEmail, isPhone } from "@/utils/validate.js";
export default {
  name: 'ContactForm',
  data() {
    return {
      showResult: false,
      formData: {
        name: '',
        email: '',
        phone: '',
        subject: '',
        detail: '',
      },
      rules: {
        name: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入你的名字"));
              } else {
                callback();
              }
            }
          }
        ],
        email: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入电子邮件地址"));
              } else {
                if (!isEmail(value)) {
                  callback(new Error("电子邮件地址格式不正确"));
                } else {
                  callback();
                }
              }
            },
            required: false,
            trigger: ["blur", "change"],
          },
        ],
        phone: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入手机号码"));
              } else {
                if (!isPhone(value)) {
                  callback(new Error("手机号码格式不正确"));
                } else {
                  callback();
                }
              }
            },
            required: true,
            trigger: ["blur", "change"],
          },
        ],
        subject: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入描述"));
              } else {
                callback();
              }
            }
          }
        ],
      },
      result: `<p class="success-message">您的消息已成功发送. 我们会尽快和你联系.</p>`
    }
  },
  methods: {
    sendEmail() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$message({
            message: '您的消息已成功发送. 我们会尽快和你联系',
            type: 'success'
          });
          this.showResult = true
		  this.$refs['form'].resetFields();
        } else {
          return false
        }
      })

    }
  }
}
</script>
