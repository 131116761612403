<template>
	<Layout>
		<!-- Start Timeline Area -->
		<div class="rwt-timeline-area rn-section-gap">
			<div class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
				<div class="row row--15">
					<div class="col-lg-4 col-md-6 col-sm-12 col-12" v-for="(service, index) in solutionPages" :key="index" @click="goDetails(service)">
						<div :class="`service gallery-style text-start`" data-aos="fade-up" data-aos-delay="70">
							<div class="inner">
								<div class="content">
									<h4 class="title description-title" v-html="service.title" style="text-align: center;"></h4>
									<p class="description" v-html="service.description" style="text-align: center;" />
								</div>
								<div class="image">
									<img :src="service.image" style="width: 100%;height: 288px;background-size:100% 100%;" alt="Service Images" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<Separator />
	</Layout>
</template>

<script>
	import Layout from '@/components/common/Layout';
	import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb';
	import Separator from "@/components/elements/separator/Separator";
	import SectionTitle from "@/components/elements/sectionTitle/SectionTitle";
	import Timeline from "@/components/elements/timeline/Timeline";
	import { solutionList } from "@/database/database.js"
	export default {
		name: 'Solution',
		components: { Timeline, SectionTitle, Separator, Layout, Breadcrumb },
		data() {
			return {
				//解决方案
				solutionPages: [],

			}
		},
		created() {
			this.solutionPages = solutionList
		},
		mounted() {
		},
		methods: {
			goDetails(row) {
				// this.$router.push({ path: `/solution-details/${row.id}` })
				let routeUrl = this.$router.resolve({
					path: `/solution-details/${row.id}`,
				});
				window.open(routeUrl.href, '_blank');
			}
		},


	}
</script>
<style lang="scss" scoped>
	.description {
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2; /*超出两行隐藏*/
	}
	.description-title {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
</style>
