<template>
    <Layout>
        <div class="rn-blog-details-area">
            <div class="post-page-banner rn-section-gapTop">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            <div class="content text-center">
                                <div class="page-title">
                                    <h1 class="theme-gradient" v-html="post.title"/>
                                </div>
                                <ul class="rn-meta-list">
                                    <li>
                                        <Icon name="user" class="size-20"/> <router-link to="#">{{post.author}}</router-link>
                                    </li>
                                    <li>
                                        <Icon name="calendar" class="size-20"/> {{ post.publishedAt }}
                                    </li>
                                </ul>
                                <div class="thumbnail alignwide mt--60">
                                    <el-image  :preview-src-list='[post.img]' class="w-100 radius"
                                         :src="post.img"
                                         :alt="post.title" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blog-details-content pt--60 rn-section-gapBottom">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            <div class="content">
                                <p v-html="post.body"/>

                                <div class="category-meta">
                                    <span class="text">Tags:</span>
                                    <div class="tagcloud">
                                        <a :href="'/category/' + item" v-for="(item,index) in post.tags" :key=index>{{item}}</a>
                                    </div>
                                </div>

                                <!-- Start Contact Form Area  -->
                                <!-- <div class="rn-comment-form pt--60">
                                    <div class="inner">
                                        <div class="section-title">
                                            <span class="subtitle">留下你的精彩点评</span>
                                            <h2 class="title">填写评论</h2>
                                        </div>
                                        <form class="mt--40" action="#">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-12 col-12">
                                                    <div class="rnform-group">
                                                        <input type="text" placeholder="如何称呼">
                                                    </div>
                                                    <div class="rnform-group">
                                                        <input type="email" placeholder="Email">
                                                    </div>
                                                    <div class="rnform-group">
                                                        <input type="text" placeholder="你的官网">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-12 col-12">
                                                    <div class="rnform-group">
                                                        <textarea placeholder="精彩评论内容"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="blog-btn">
                                                        <a class="btn-default" href="#">
                                                            <span>发表评论</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div> -->
                                <!-- End Contact Form Area  -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from '@/components/common/Layout'
    import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
    import Icon from '@/components/icon/Icon'
    import BlogPostMixin from '@/mixins/BlogPostMixin'

    export default {
        name: 'DetailsPage',
        components: {Icon, Layout, Breadcrumb},
        mixins: [BlogPostMixin],
        data() {
            return {
                id: this.$route.params.id,
                post: {}
            }
        },
        methods: {
            getPost(postId) {
                this.post = this.posts.find(item => item.id === postId);
            }
        },
        mounted() {
            this.getPost(this.id);
        }
    }
</script>
