<template>
	<Layout header-class="header-transparent" :footer-style="3">
		<!-- Start Slider Area -->
		<div class="slider-area slider-style-1 variation-default height-850 bg_image" data-black-overlay="7" :style="{'background-image': `url(${detailData.topBackgroundImg})`}">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="inner text-center">
							<!-- <span class="subtitle" data-aos="fade-up" data-aos-delay="100">
                                DIGITAL CONSULTING AGENCY
							</span>-->
							<h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">{{ detailData.title }}</h1>
							<p class="description" data-aos="fade-up" data-aos-delay="200">{{ detailData.description }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- End Slider Area -->
		<!-- Start About Area -->
		<div class="rwt-about-area rn-section-gap">
			<div class="container">
				<div class="row row--30 align-items-center">
					<div class="col-lg-5">
						<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
							<el-image  :preview-src-list='[detailData.background.url]' class="w-100" :src="detailData.background.url" alt="About Images" />
						</div>
					</div>
					<div class="col-lg-7 mt_md--40 mt_sm--40">
						<div class="content">
							<div class="section-title">
								<h2 class="title theme-gradient" data-aos="fade-up" data-aos-delay="280">
									案例背景
									<br />
								</h2>
								<p data-aos="fade-up" data-aos-delay="310">{{ detailData.background.text }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- End About Area -->
		<!-- Start Service Area -->
		<div class="rn-service-area rn-section-gapBottom pt--100">
			<div class="row">
				<div class="col-lg-12">
					<SectionTitle text-align="center" subtitle="技术难点" title="面临挑战." description data-aos="fade-up" />
				</div>
			</div>
			<div class="container">
				<ServiceOne service-style="service__style--1 icon-circle-style" text-align="center" icon-size="39" :serviceList="detailData.serviceList" />
			</div>
		</div>
		<!-- End Service Area -->

		<Separator />

		<!-- Start Timeline Area  -->
		<div class="rwt-timeline-area rn-section-gap">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<SectionTitle text-align="center" subtitle="详细方案" title="解决方案." description />
					</div>
				</div>
				<div class="row mt_dec--20">
					<div class="col-lg-12">
						<Timeline :timeline-data="detailData.solutionData" />
					</div>
				</div>
			</div>
		</div>
		<!-- End Timeline Area -->
		<!-- Start About Area -->
		<div class="rwt-about-area rn-section-gap">
			<div class="container">
				<div class="row row--30 align-items-center">
					<div class="col-lg-12 mt_md--40 mt_sm--40">
						<div class="content">
							<div class="section-title">
								<h2 class="title theme-gradient" data-aos="fade-up" data-aos-delay="280">
									价值体现
									<br />
								</h2>
								<p data-aos="fade-up" v-for="(item,index) in detailData.valueData" :key="index" data-aos-delay="310">{{ item.text }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- End About Area -->

		<!-- Start Elements Area -->
		<div class="rwt-callto-action-area rn-section-gapBottom">
			<div class="wrapper">
				<CallToAction :style-type="5" title="准备好跟我们携手合作了吗？" subtitle="你的最佳合作伙伴" btn-text="联系我们" />
			</div>
		</div>
		<!-- End Elements Area -->
	</Layout>
</template>

<script>
	import Layout from '@/components/common/Layout'
	import Icon from '@/components/icon/Icon'
	import Separator from '@/components/elements/separator/Separator'
	import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
	import ServiceOne from '@/components/elements/service/ServiceOne'
	import Timeline from '@/components/elements/timeline/Timeline'
	import Testimonial from '@/components/elements/testimonial/Testimonial'
	import BlogPostMixin from '@/mixins/BlogPostMixin'
	import BlogPost from '@/components/blog/BlogPost'
	import CallToAction from '@/components/elements/callToAction/CallToAction'
	import { caseList } from "@/database/database.js"
	export default {
		name: 'case-detail',
		components: {
			ServiceOne,
			CallToAction,
			BlogPost,
			Testimonial,
			Timeline,
			SectionTitle,
			Separator,
			Icon,
			Layout
		},
		mixins: [BlogPostMixin],
		data() {
			return {
				detailData: {},
			}
		},
		created() {
			if (this.$route.params.id) {
				this.detailData = caseList.find(item => { return item.id === this.$route.params.id - 0 })
			}
		},
		mounted() {},
		watch: {
			$route(to, from) {
				this.$router.go(0)

			}
		},
	}
</script>
