<template>
	<Layout>
		<!-- Start Timeline Area -->
		<div class="rwt-timeline-area rn-section-gap">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<SectionTitle text-align="center" subtitle="详细方案" :title="SolutionDetails.title" data-aos="fade-up" data-aos-delay="60" />
					</div>
				</div>

				<div class="row">
					<div class="col-lg-12">
						<Timeline :timeline-data="SolutionDetails.detail" />
					</div>
				</div>
			</div>
		</div>
		<!-- End Timeline Area -->

		<Separator />
	</Layout>
</template>

<script>
	import Layout from '@/components/common/Layout';
	import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb';
	import Separator from "@/components/elements/separator/Separator";
	import SectionTitle from "@/components/elements/sectionTitle/SectionTitle";
	import Timeline from "@/components/elements/timeline/Timeline";
	import { solutionList } from "@/database/database.js"

	export default {
		name: 'SolutionDetails',
		components: { Timeline, SectionTitle, Separator, Layout, Breadcrumb },
		data() {
			return {
				SolutionDetails: [],
				title: ''
			}
		},

		methods: {

		},
		created() {

		},
		mounted() {

			if (this.$route.params.id) {
				this.SolutionDetails = solutionList.find(item => { return item.id === this.$route.params.id - 0 })
			}

		},
		watch: {
			$route(to, from) {
				this.$router.go(0)

			}
		},
	}
</script>
