<template>
	<Layout>
		<div v-for="(item,index) in listData" :key="index">
			<div class="rwt-advance-tab-area rn-section-gap">
				<div class="container">
					<div class="row mb--40">
						<div class="col-lg-12">
							<SectionTitle textAlign="center" :subtitle="item.description" :title="item.title" data-aos="fade-up" />
						</div>
					</div>
					<AdvancedTab :tab-data="item.caseListData" />
					<div class="button-group text-center mt--20" data-aos="fade-up" data-aos-delay="200">
						<a class="btn-default btn-large round btn-icon" href="javascript:void(0)" @click="goDetails(item)">
							查看详情
							<Icon name="arrow-right" />
						</a>
					</div>
				</div>
			</div>
			<!-- End Tab Area  -->

			<Separator />
		</div>
	</Layout>
</template>

<script>
	import Layout from '@/components/common/Layout'
	import Icon from '@/components/icon/Icon'
	import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
	import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
	import Separator from '@/components/elements/separator/Separator'
	import AdvancedTab from '@/components/elements/tab/AdvancedTab'
	import { caseList } from "@/database/database.js"
	export default {
		name: 'SuccessCase',
		components: { AdvancedTab, Separator, SectionTitle, Breadcrumb, Layout, Icon },
		data() {
			return {
				listData: caseList,
			}
		},
		// created() {

		// 			this.listData = list
		// 	},
		methods: {
			goDetails(row) {
				// this.$router.push({ path: `/case-details/${row.id}` })
				let routeUrl = this.$router.resolve({
					path: `/case-details/${row.id}`,
				});
				window.open(routeUrl.href, '_blank');
			}
		},
	}
</script>
