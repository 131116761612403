<template>
    <div class="newsletter-area rn-newsletter-default">
        <div class="container">
            <div class="row row--0 newsletter-wrapper align-items-center border-top-bottom">
                <div class="col-lg-4">
                    <div class="newsletter-section-title">
                        <h3 class="title">保持最新</h3>
                        <p class="description">不要错过最新的高新资讯、动态和趋势.</p>
                    </div>
                </div>
                <div class="col-lg-8">
                    <form class="rn-newsletter mt_md--20 mt_sm--20" action="#">
                        <div class="form-group">
                            <input type="email" placeholder="电子邮箱" />
                        </div>
                        <div class="form-group">
                            <button class="btn-default">订阅</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Newsletter'
    }
</script>