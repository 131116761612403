<template>
	<div>
		<!-- Start Footer Area  -->
		<footer v-if="data.showFooter" class="rn-footer footer-style-default variation-two">
			<div class="footer-top">
				<div class="container">
					<div class="row">
						<div class="col-lg-3 col-md-6 col-sm-6 col-12 footer-mg-b-20">
							<div class="rn-footer-widget">
								<Logo />
								<h3 class="title" style="margin-top: 20px;">云南高兴技术有限公司</h3>
								<h6 class="subtext">咨询电话：156 8770 0306</h6>
								<h6 class="subtext">地址：云南省昆明市五华区人民西路730号西府景苑2幢1705室</h6>
								<div class="inner footer_my_switcher" id="footer_my_switcher" v-if="data.showThemeSwitcherButton">
									<h6 class="subtitle"></h6>
									<ul class="social-icon social-default justify-content-start">
										<el-popover placement="top" trigger="hover">
											<img class="el-popover-img" :src="require('@/assets/images/about/wechat.jpg')" alt />
											<li slot="reference">
												<a href="javascript:void(0);">
													<!--微信公众号-->
													<!-- <Icon name="wechat" /> -->
													<img class="icon-img light-icon" :src="require(`@/assets/images/icons/wechatlight.svg`)" alt="Sun images" />
													<img class="icon-img dark-icon" :src="require(`@/assets/images/icons/wechat.svg`)" alt="Moon Images" />
												</a>
											</li>
										</el-popover>
										<el-popover placement="top" trigger="hover">
											<img class="el-popover-img" :src="require('@/assets/images/about/douyin.png')" alt />
											<li slot="reference">
												<a href="javascript:void(0);">
													<!-- 抖音号-->
													<img class="icon-img light-icon" :src="require(`@/assets/images/icons/douyinlight.svg`)" alt="Sun images" />
													<img class="icon-img dark-icon" :src="require(`@/assets/images/icons/douyin.svg`)" alt="Moon Images" />
													<!-- <Icon name="twitter" /> -->
												</a>
											</li>
										</el-popover>

										<el-popover placement="top" trigger="hover">
											<img class="el-popover-img" :src="require('@/assets/images/about/bilibili.png')" alt />
											<li slot="reference">
												<a href="javascript:void(0);">
													<!-- bilibili -->
													<img class="icon-img light-icon" :src="require(`@/assets/images/icons/bilibililight.svg`)" alt="Sun images" />
													<img class="icon-img dark-icon" :src="require(`@/assets/images/icons/bilibili.svg`)" alt="Moon Images" />
													<!-- <Icon name="instagram" /> -->
												</a>
											</li>
										</el-popover>
										<el-popover placement="top" trigger="hover">
											<img class="el-popover-img" :src="require('@/assets/images/about/youtube.jpg')" alt />
											<li slot="reference">
												<a href="javascript:void(0);">
													<!-- youtube -->
													<img class="icon-img light-icon" :src="require(`@/assets/images/icons/youtubelight.svg`)" alt="Sun images" />
													<img class="icon-img dark-icon" :src="require(`@/assets/images/icons/youtube.svg`)" alt="Moon Images" />
													<!-- <Icon name="youtube" /> -->
												</a>
											</li>
										</el-popover>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-2 col-md-6 col-sm-6 col-12">
							<div class="rn-footer-widget">
								<h4 class="title">解决方案</h4>
								<div class="inner">
									<ul class="footer-link link-hover">
										<li v-for="(item,index) in solutionlistData" :key="index">
											<router-link target="_blank" :to="`/solution-details/${item.id}`">{{item.title}}</router-link>
										</li>
										<!-- <li>
											<router-link target="_blank" to="/solution-details/1">矿山全生命周期三维动态监管</router-link>
										</li>
										<li>
											<router-link target="_blank" to="/solution-details/2">互联网+智慧农业</router-link>
										</li>
										<li>
											<router-link target="_blank" to="/solution-details/3">专业定制化服务及支撑</router-link>
										</li>
										<li>
											<router-link target="_blank" to="/solution-details/4">智慧校园</router-link>
										</li>
										<li>
											<router-link target="_blank" to="/solution-details/5">园区可视化平台</router-link>
										</li>
										<li>
											<router-link target="_blank" to="/solution-details/6">信用卡建设平台</router-link>
										</li>-->
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-2 col-md-6 col-sm-6 col-12">
							<div class="rn-footer-widget">
								<div class="widget-menu-top">
									<h4 class="title">用户故事</h4>
									<div class="inner">
										<ul class="footer-link link-hover">
											<li v-for="(item,index) in userStoryListData" :key="index">
												<router-link target="_blank" :to="`/user-story#user-story-${item.id}`">{{item.title}}</router-link>
											</li>
											<!-- <li>
                                                <router-link target="_blank" to="/user-story#user-story-1">餐饮品牌推广</router-link>
                                            </li>
                                            <li>
                                                <router-link target="_blank" to="/user-story#user-story-2">招商系统重构</router-link>
                                            </li>
                                            <li>
                                                <router-link target="_blank" to="/user-story#user-story-3">软件工程过程+项目管理</router-link>
                                            </li>
                                            <li>
                                                <router-link target="_blank" to="/user-story#user-story-4">软硬件融合的故事</router-link>
                                            </li>
                                            <li>
                                                <router-link target="_blank" to="/user-story#user-story-6">树莓派刷学习强国</router-link>
											</li>-->
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-2 col-md-6 col-sm-6 col-12">
							<div class="rn-footer-widget">
								<h4 class="title">成功案列</h4>
								<div class="inner">
									<ul class="footer-link link-hover">
										<li v-for="(item,index) in caselistData" :key="index">
											<router-link target="_blank" :to="`/case-details/${item.id}`">{{item.title}}</router-link>
										</li>
										<!-- <li>
											<router-link target="_blank" to="/case-details/1">医疗行业定制化CRM</router-link>
										</li>
										<li>
											<router-link target="_blank" to="/case-details/2">园区可视化平台</router-link>
										</li>
										<li>
											<router-link target="_blank" to="/case-details/3">智慧校园综合管理平台</router-link>
										</li>
										<li>
											<router-link target="_blank" to="/case-details/4">信用卡中心</router-link>
										</li>-->
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-2 col-md-6 col-sm-6 col-12">
							<div class="rn-footer-widget">
								<h4 class="title">开发者中心</h4>
								<div class="inner">
									<ul class="footer-link link-hover">
										<li>
											<router-link target="_blank" to="/blog">技术分享</router-link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-1 col-md-6 col-sm-6 col-12">
							<div class="rn-footer-widget">
								<h4 class="title">公司</h4>
								<div class="inner">
									<ul class="footer-link link-hover">
										<li>
											<router-link target="_blank" to="/about#team">团队成员</router-link>
										</li>
										<li>
											<router-link target="_blank" to="/about#partner">合作伙伴</router-link>
										</li>
										<li>
											<router-link target="_blank" to="/about#service">提供服务</router-link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Newsletter v-if="data.showNewsletter" />
		</footer>
		<!-- End Footer Area  -->

		<Copyright v-if="data.showCopyright" />

		<ScrollTop />
	</div>
</template>

<script>
	import Copyright from './Copyright'
	import Button from '../../elements/button/Button'
	import Icon from '../../icon/Icon'
	import ScrollTop from './ScrollTop'
	import Logo from '../../elements/logo/Logo'
	import Newsletter from './Newsletter'
	import AppFunctions from '@/helpers/AppFunctions'
	import { caseList, solutionList, userStoryList } from "@/database/database.js"
	export default {
		name: 'Footer',
		components: { Newsletter, Logo, ScrollTop, Icon, Button, Copyright },
		props: {
			data: {
				default: null
			}
		},

		data() {
			return {
				AppFunctions,
				caselistData: caseList,
				solutionlistData: solutionList,
				userStoryListData: userStoryList,
			}
		},
		created() {


		},
	}
</script>
<style lang="scss" scoped>
	.icon-img {
		width: 26px;
		height: 26px;
	}
	.el-popover-img {
		width: 200px;
		// height: 100px;
		display: block;
		margin: auto;
	}
</style>
