<template>
	<div>
		<baidu-map class="map" :center="center" :zoom="zoom">
			<bm-marker :position="center" :dragging="true" @click="infoWindowOpen" :icon="{url:require('@/assets/images/map/marker.png'), size: {width: 20, height: 40}}">
				<bm-info-window :show="show" @close="infoWindowClose" @open="infoWindowOpen">
					<div>云南高兴技术有限公司</div>
					<div>云南省昆明市五华区人民西路730号西府景苑2幢1705</div>
				</bm-info-window>
			</bm-marker>
			<bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
			<bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
		</baidu-map>
	</div>
</template>

<script>
	export default {
		/**
		* 百度地图
		*/
		name: 'DetailsMap',
		data() {
			return {
				map: '',
				zoom: 16,
				show: false,
				center: {
					lng: 102.654943,
					lat: 25.053836
				},

			};
		},
		created() {
		},
		mounted() {
		},
		methods: {


			//点击 Marker 弹出 InfoWindow
			infoWindowClose() {
				this.show = false
			},
			infoWindowOpen() {
				this.show = true
			}

		}
	};
</script>

<style scoped>
	.map {
		width: 100%;
		height: 600px;
		max-height: 600px;
	}
</style>
