<template>
	<Layout>
		<div v-for="(item,index) in tabDataList" :key="item.id">
			<!-- Start 用户故事 -->
			<div class="rwt-tab-area rn-section-gap" :id="`user-story-${item.id}`">
				<div class="container">
					<div class="row mb--40">
						<div class="col-lg-12">
							<SectionTitle :key="item.id" text-align="center" :subtitle="item.subtitle" :title="item.title" data-aos="fade-up" data-aos-delay="60" />
						</div>
					</div>
					<div class="row row row--30 align-items-center">
						<div class="col-lg-3">
							<el-image   :preview-src-list='[item.tabData.tabImage]'  class="radius-small" :src="item.tabData.tabImage" alt="Tab Image" />
						</div>
						<div class="col-lg-9 mt_md--40 mt_sm--40 order-2 order-lg-1">
							<div :class="[`rn-default-tab`, {'style-two': 1 === 2}]">
								<ul class="nav nav-tabs tab-button" role="tablist">
									<li class="nav-item tabs__tab" role="presentation" v-for="(tabMenu, index1) in item.tabData.tabContent" :key="index1">
										<button class="nav-link text-capitalize" :class="{'active': index1 === 0}" :id="`${tabMenu.menu}-tab`" data-bs-toggle="tab" :data-bs-target="`#${tabMenu.menu}`" type="button" role="tab" :aria-controls="tabMenu.menu" :aria-selected="index1 === item.activeTab ? 'true' : 'false'" @click="tabDataList[index].activeTab = index1">{{ tabMenu.menu }}</button>
									</li>
								</ul>
								<div class="rn-tab-content tab-content">
									<div class="tab-pane fade" :class="{'show active': index2 === tabDataList[index].activeTab }" :id="content.id" role="tabpanel" :aria-labelledby="`${content.id}-tab`" v-for="(content, index2) in item.tabData.tabContent" :key="index2">
										<div v-html="content.content" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- End 用户故事 -->
			<Separator />
		</div>
	</Layout>
</template>

<script>
	import Layout from '@/components/common/Layout'
	import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
	import Separator from '@/components/elements/separator/Separator'
	import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
	import { userStoryList } from "@/database/database.js"
	export default {
		name: 'UserStory',
		components: {SectionTitle, Separator, Layout, Breadcrumb },
		data() {
			return {
				tabDataList: userStoryList,
			}
		}
	}
</script>
