<template>
	<div class="about-area about-style-4 rn-section-gap">
		<div class="container">
			<div class="row row--40 align-items-center">
				<div class="col-lg-6">
					<Video :video-data="videoData" column="col-12" />
				</div>
				<div class="col-lg-6 mt_md--40 mt_sm--40">
					<div class="content">
						<div class="inner">
							<h3 class="title">
								高兴技术是一家从事
								<br />专业软件定制化开发及服务
								<br />
								<strong>的技术型创新科技公司.</strong>
							</h3>
							<ul class="feature-list">
								<li>
									<Icon name="check" size="20" icon-class="icon" />
									<div class="title-wrapper">
										<h4 class="title">专业软件定制化开发</h4>
										<p class="text">专业态度，高效流程，资深把控，便捷省心</p>
									</div>
								</li>
								<li>
									<Icon name="check" size="20" icon-class="icon" />
									<div class="title-wrapper">
										<h4 class="title">信息化数字化服务</h4>
										<p class="text">深耕久积，举重若轻，技术驱动，探索创新</p>
									</div>
								</li>
							</ul>
							<div class="about-btn mt--30">
								<router-link class="btn-default" to="/contact">联系我们</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Icon from '../../icon/Icon'
	import Video from "../video/Video";

	export default {
		name: 'AboutOne',
		components: { Video, Icon },
		props: {
			image: {}
		},
		data() {
			return {
				videoData: [
					{
						thumb: this.image,
						src: require(`@/assets/video/small-box.mp4`),
					}
				]
			}
		}
	}
</script>
